import React from "react"
import { Row, Col, Container, Card } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO
      title="Home"
      keywords={[
        `therapy`,
        `anger management`,
        `court evaluation`,
        `brief therapy`,
      ]}
    />
    <Container className="text-left">
      <Row>
        <Col>
          <h2 className="text-center">Jennifer Marco, MSW, LCSW</h2>
        </Col>
      </Row>
      <Row className="d-inline-flex flex-wrap justify-content-center">
        <Card style={{ width: "30rem", maxWidth: "90vw", margin: "1rem" }}>
          <Card.Header as="H3">About Jennifer</Card.Header>
          <Card.Body>
            <Card.Text>
              Jennifer is a Master's level Licensed Clinical Social Worker with
              training as a psychotherapist. She is licensed to practice in
              Pennsylvania and specializes in anger management as well as
              individual and marriage/relationship counseling.
              <br /> <br />
              Jennifer has over twenty-five years in the field with a background
              in community mental health and private practice. She has spoken on
              anxiety disorders, depression and anger management for the Shore
              Memorial Hospital Wellness Center, Shore Memorial's Annual Women's
              Conference, as well as several other business and community events
              in Atlantic County. She also runs a monthly anger management
              workshop.
              <br /> <br />
              Zoom sessions are welcomed.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "30rem", maxWidth: "90vw", margin: "1rem" }}>
          <Card.Header as="H3">Therapeutic Approach</Card.Header>
          <Card.Body>
            <Card.Text>
              The client and therapist are an interactive team that work
              together to address pertinent issues that have not been resolved
              in the client's life. A comfortable environment allows each client
              an opportunity to explore and address areas of stress in their
              lives.
              <br /> <br />
              Cognitive Behavioral Therapy (CBT) is integrated in psychotherapy
              sessions when needed as a therapeutic tool to reduce stressors.
              Most importantly, an environment of trust is established allowing
              clients to communicate their concerns. Sessions are focused but
              also not rushed to encourage a natural flow of conversation around
              problems.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "30rem", maxWidth: "90vw", margin: "1rem" }}>
          <Card.Header as="H3">Education</Card.Header>
          <Card.Body>
            Masters Degree in Social Work from University of Pennsylvania
            <br />
            <br />
            Undergraduate degree from Montclair State University Majored in
            English with a minor in Psychology
          </Card.Body>
        </Card>
        <Card style={{ width: "30rem", maxWidth: "90vw", margin: "1rem" }}>
          <Card.Header as="H3">New Clients</Card.Header>
          <Card.Body>
            <Card.Text>
              New clients are welcome to call and talk directly to the
              therapist. Email{" "}
              <a href="mailto:info@jennifermarco.com">
                info{`@`}jennifermarco.com
              </a>{" "}
              or call <a href="tel:+16096539900">(609) 653-9900</a> for more
              information. Your calls will be returned promptly.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "30rem", maxWidth: "90vw", margin: "1rem" }}>
          <Card.Header as="H3">Insurance</Card.Header>
          <Card.Body>
            <Card.Text>
              Please note: Jennifer Marco is no longer accepting insurance
              plans.
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
